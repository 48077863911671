$(document).ready(function () {
    mascaras();
});

$(document).on({
    ajaxStart: function () {
        $('#loading').modal('show');
    },
    ajaxStop: function () {
        $('#loading').modal('hide');
    },
    ajaxError: function (event, res) {
        $('#loading').modal('hide');
    }
});

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        // 'Access-Control-Allow-Origin': '*',
        // 'Accept': 'application/json',
        // 'X-Requested-With': 'XMLHttpRequest',
        // 'Content-Type': 'application/json',
    },
    error: function (jqXHR, exception) {
        var msg = '';
        if (jqXHR.status === 0) {
            msg = Lang.get('messages.msg.sem_conexao_verifique_internet');
        } else if (jqXHR.status == 404) {
            msg = Lang.get('messages.msg.erro_404');
        } else if (jqXHR.status == 500) {
            msg = Lang.get('messages.msg.erro_500');
        } else if (exception === 'parsererror') {
            msg = Lang.get('messages.msg.erro_parse_json');
        } else if (exception === 'timeout') {
            msg = Lang.get('messages.msg.timeout');
        } else if (exception === 'abort') {
            msg = Lang.get('messages.msg.ajax_abortada');
        } else {
            msg = jqXHR.responseJSON.message;
        }

        $('#alerta').addClass('alert-danger').removeClass('hidden');
        $('#alerta p').text(msg);
    },
});

window.notificacaoSucesso = function (msg = 'Sucesso') {
    $.notify({
        message: msg,
        allow_dismiss: false,
        icon: 'icon-ios-checkmark',
    }, {
        type: 'success'
    });
};

window.notificacaoErro = function (msg = 'Erro') {
    $.notify({
        message: msg,
        allow_dismiss: false,
        icon: 'icon-ios-close',
    }, {
        type: 'danger'
    });
};

window.notificacaoAviso = function (msg = 'Atenção') {
    $.notify({
        message: msg,
        allow_dismiss: false,
        icon: 'icon-ios-warning',
    }, {
        type: 'warning'
    });
};

function mascaras() {
    $(document).on('keydown', '.cpfcnpj', function (event) {
        try {
            $(".cpfcnpj").unmask();
        } catch (e) {
        }

        var tamanho = $(this).val().length;

        if (tamanho < 11) {
            $(".cpfcnpj").mask("999.999.999-99");
        } else {
            $(".cpfcnpj").mask("99.999.999/9999-99");
        }

        // ajustando foco
        var elem = this;
        setTimeout(function () {
            // mudo a posição do seletor
            elem.selectionStart = elem.selectionEnd = 10000;
        }, 0);
        // reaplico o valor para mudar o foco
        var currentValue = $(this).val();
        $(this).val('');
        $(this).val(currentValue);
    });

    $(".rg").keydown(function () {
        try {
            $(".rg").unmask();
        } catch (e) {
        }

        var tamanho = $(this).val().length;

        if (tamanho < 7) {
            $(".rg").mask("9.999.999");
        } else {
            $(".rg").mask("99.999.999-9");
        }

        var elem = this;
        setTimeout(function () {
            elem.selectionStart = elem.selectionEnd = 10000;
        }, 0);
        var currentValue = $(this).val();
        $(this).val('');
        $(this).val(currentValue);
    });

    $(document).on('keydown', '.cep', function (event) {
        $(this).mask("99999-999");
    });

    $(document).on('keydown', '.dinheiro', function (event) {
        $(this).mask('000.000.000.000.000,00', {reverse: true});
    });

    $(document).on('keydown', '.telefone', function (event) {
        $(this).mask("(99) 99999-9999");
    });
}
